<template>
  <div class="margin-bottom">
    <v-btn
      class="btn-export"
      color="primary"
      v-if="allowDownload && data && data.length"
      @click="exportData">
        <v-icon x-large>file_download</v-icon>
    </v-btn>

    <v-tabs v-if="starredTab" background-color="#303030" v-model="tab">
      <v-tab>All</v-tab>
      <v-tab>Starred</v-tab>
    </v-tabs>
    <table class="listable">
      <thead>
        <tr>
          <slot name="header"></slot>
        </tr>
      </thead>
      <tbody>
        <template v-for="o in data">
          <slot name="object" v-bind:o="o">
            <!-- Fallback content -->
            <tr :key="o.id">
              <td>
                [MISSING OBJECT SLOT] {{ o }}
              </td>
            </tr>
          </slot>
        </template>
      </tbody>
    </table>
    <div v-if="firstLoading">
      <v-progress-circular :size="70" :width="7" indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="firstLoadFailed">
      <v-alert :value="true" type="error">
        {{error.message}}
        <v-btn color="error" @click="loadFirst">Try again</v-btn>
      </v-alert>
    </div>
    <div v-else-if="!data.length" style="padding: 20px;">
      There's nothing here, yet.
    </div>

    <div>
      <div v-if="nextLoading">
        <v-progress-circular :size="70" :width="7" indeterminate color="primary"></v-progress-circular>
      </div>
      <v-alert v-else-if="nextLoadFailed" :value="true" type="error">
        {{error.message}}
        <v-btn color="error" @click="loadNext">Try again</v-btn>
      </v-alert>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { writeToString } from '@fast-csv/format';
import api from '@/services/api';

export default {
  name: 'ListView',
  props: {
    source: Object,
    allowDownload: {
      type: Boolean,
      default: true,
    },
    starredTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageSize: 25,
      firstLoading: true,
      firstLoadFailed: false,
      nextLoading: false,
      nextLoadFailed: false,
      hasNext: false,
      cursorNext: '',
      data: [],
      error: '',
      isActive: true,
      isDirty: false,
      tab: 0,
    };
  },
  created() {
    this.loadFirst();
    this.$globalEventBus.$on('object-edited', this.onObjectEdited);
  },
  beforeDestroy() {
    this.$globalEventBus.$off('object-edited', this.onObjectEdited);
  },
  activated() {
    this.isActive = true;
    if (this.isDirty) {
      this.loadFirst();
    }
  },
  deactivated() {
    this.isActive = false;
  },
  watch: {
    source(newSource, oldSource) {
      if (!_.isEqual(newSource, oldSource)) {
        this.loadFirst();
      }
    },
    tab(newTab, oldTab) {
      if (newTab !== oldTab) {
        this.loadFirst();
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.scrollListener);
  },
  unmounted() {
    document.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    onObjectEdited() {
      if (this.isActive) {
        this.loadFirst();
      } else {
        this.isDirty = true;
      }
    },
    fetch(params) {
      let env;
      if (this.source.env) {
        env = api.envs[this.source.env];
      } else {
        env = api.kernel;
      }

      return env.get(this.source.path, {
        ...this.source.query,
        ...params,
      });
    },
    loadFirst() {
      this.data = [];
      this.firstLoading = true;
      this.firstLoadFailed = false;
      this.nextLoading = false;
      this.nextLoadFailed = false;
      this.isDirty = false;

      const query = {
        limit: this.pageSize,
      };
      if (this.starredTab && this.tab === 1) {
        query.starred = true;
      }

      this.fetch(query).then((data) => {
        this.firstLoading = false;
        this.data = data.data;
        this.hasNext = data.has_next;
        this.cursorNext = data.cursor_next;
      }, (data) => {
        this.firstLoading = false;
        this.firstLoadFailed = true;
        this.error = data;
      });
    },
    loadNext() {
      if (this.nextLoading) return;
      this.nextLoading = true;
      this.nextLoadFailed = false;
      this.fetch({
        limit: this.pageSize,
        cursor: this.cursorNext,
      }).then((data) => {
        this.nextLoading = false;
        this.data = this.data.concat(data.data);
        this.hasNext = data.has_next;
        this.cursorNext = data.cursor_next;
      }, (data) => {
        this.nextLoading = false;
        this.nextLoadFailed = true;
        this.error = data;
      });
    },
    scrollListener() {
      if (!this.hasNext) {
        return;
      }

      const elem = document.documentElement;

      if (elem.scrollTop + elem.clientHeight >= elem.scrollHeight - 200) {
        this.loadNext();
      }
    },
    async exportData() {
      const dotizedData = this.data.map((x) => this.dotize(x));
      const csvFile = await writeToString(dotizedData, { headers: true });
      saveAs(new Blob([csvFile], { type: 'text/plain;charset=utf-8' }), 'kernel_export.csv');
    },
    dotize(obj) {
      const result = {};
      for (const [key, value] of Object.entries(obj)) {
        if (value && typeof value === 'object') {
          const partialResult = this.dotize(value);
          for (const [partialResultKey, partialResultValue] of Object.entries(partialResult)) {
            const newKey = [key, partialResultKey].join('.');
            result[newKey] = partialResultValue;
          }
        } else {
          result[key] = value;
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
  .margin-bottom {
    margin-bottom: 60px;
  }

  .btn-export {
    position: fixed;
    bottom: 10px;
    right: 10px;
    height: 50px !important;
    opacity: 0.7 !important;

    &:hover {
      opacity: 1 !important;
    }
  }
</style>
